import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import dpdAvatarImg from "../../assets/icons/dpd-avatar.png";
import dpdCubeImg from "../../assets/icons/dpd-cube.png";
import revenue from "../../assets/icons/revenue.png";
import dpdPickupImg from "../../assets/icons/dpd-pickup.png";
import jumbotronBg from "../../assets/pages/home/jumbotron-bg.png";
import { CardContainer } from "../../components/CardContainer/CardContainer";
import { CountrySearch } from "../../components/CountrySearch/CountrySearch";
import { GetInTouch } from "../../components/GetInTouch/GetInTouch";
import { Section } from "../../components/Section/Section";
import { Heading1 } from "../../components/Typography/Heading1";
import { Heading2 } from "../../components/Typography/Heading2";
import { Heading5 } from "../../components/Typography/Heading5";
import { HOME } from "../../constants/actions";
import { ANIMATIONS } from "../../constants/constants";
import {
  EMPLOYEES,
  GLOBAL_PICKUP_POINTS,
  PARCELS_DELIVERED,
  REVENUE,
} from "../../data/stats";
import { Annotation } from "../../components/Typography/Annotation";
// import { useCallback } from "react";
// import { useNavigate } from "react-router-dom";

export const Home = () => {
  // const navigate = useNavigate();
  // const onActionBtnClick = useCallback(() => {
  //   navigate("/");
  // }, []);

  return (
    <Trackable interfaceId={HOME.INTERFACE_ID} loadId={HOME.ON_LOAD}>
      <Section bgImg={jumbotronBg}>
        <Col md={8}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={500}
            animateOnce={true}
          >
            <Heading1>We bring the world closer to you</Heading1>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.ZOOM_IN}
            delay={1000}
            animateOnce={true}
          >
            <CountrySearch />
          </ScrollAnimation>

          <div style={{ marginTop: "50px" }}>
            <Col md={12}>
              <ScrollAnimation
                animateIn={ANIMATIONS.FADE_IN_UP}
                delay={1500}
                animateOnce={true}
              >
                <Heading2>Your international delivery experts</Heading2>
              </ScrollAnimation>
            </Col>

            <ScrollAnimation
              animateIn={ANIMATIONS.FADE_IN_UP}
              delay={2000}
              animateOnce={true}
            >
              <CardContainer
                cards={[
                  {
                    cardImg: dpdAvatarImg,
                    cardTitle: <Heading1>{EMPLOYEES}</Heading1>,
                    cardText: (
                      <>
                        <Heading5>Employees</Heading5>
                      </>
                    ),
                  },
                  {
                    cardImg: dpdCubeImg,
                    cardTitle: <Heading1>{PARCELS_DELIVERED}</Heading1>,
                    cardText: <Heading5>Parcels Delivered Worldwide</Heading5>,
                  },
                  {
                    cardImg: dpdPickupImg,
                    cardTitle: <Heading1>{GLOBAL_PICKUP_POINTS}</Heading1>,
                    cardText: <Heading5>Pickup Points Globally</Heading5>,
                  },
                  {
                    cardImg: revenue,
                    cardTitle: <Heading1>{REVENUE}</Heading1>,
                    cardText: (
                      <div>
                        <Heading5>Revenue</Heading5>
                        <Annotation>*Asendia revenue excluded</Annotation>
                      </div>
                    ),
                  },
                ]}
                columnSpan={6}
              />
            </ScrollAnimation>
          </div>
        </Col>
      </Section>

      <GetInTouch
      // cardTitle={"Let’s Talk International"}
      // cardText={
      //   "DPD’s international chat can answer any questions you might have regarding our \n international services."
      // }
      // callToActionBtnText={"Ask me anything"}
      // onActionBtnClick={onActionBtnClick}
      />
    </Trackable>
  );
};
